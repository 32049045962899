<!-- eslint-disable vue/attributes-order -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(submitForm)">
        <!-- Hidden Fields -->
        <input type="hidden" v-model="reportData.report_type">
        <input type="hidden" v-model="reportData.time_zone">
        <input type="hidden" v-model="reportData.client_display_name">
        <input type="hidden" v-model="reportData.site_address">

        <!-- Dynamic Select Fields -->
        <b-form-group label="Type Of Occurrence" label-for="occurence_type">
          <v-select id="occurence_type" v-model="reportData.occurence_type" :options="occurrenceTypes" required />
        </b-form-group>

        <b-form-group label="Client Name" label-for="clientname">
          <v-select id="clientname" v-model="reportData.clientname" :options="clients" required />
        </b-form-group>

        <b-form-group label="Site" label-for="sitename">
          <v-select id="sitename" v-model="reportData.sitename" :options="sites" required />
        </b-form-group>

        <!-- Text Inputs -->
        <b-form-group label="Police Division" label-for="police_division">
          <b-form-input id="police_division" v-model="reportData.police_division" required />
        </b-form-group>

        <b-form-group label="Place Of Occurrence" label-for="occurrence_place">
          <b-form-input id="occurrence_place" v-model="reportData.occurrence_place" required />
        </b-form-group>

        <!-- Date / Time Inputs -->
        <b-form-group label="Date Of Occurrence" label-for="occurrence_date">
          <b-form-datepicker id="occurrence_date" v-model="reportData.occurrence_date" required />
        </b-form-group>

        <!-- Additional Fields Here -->

        <!-- Textarea for SYNOPSIS -->
        <b-form-group label="SYNOPSIS" label-for="details">
          <b-form-textarea id="details" v-model="reportData.details" rows="4" required />
        </b-form-group>

        <!-- Radial List for Open / Closed -->
        <b-form-group label="Open / Closed">
          <b-form-radio-group id="is_open" v-model="reportData.is_open" :options="statusOptions" required />
        </b-form-group>

        <!-- Multi-File Upload -->
        <b-form-group label="Upload Attachments">
          <b-form-file v-model="files" :multiple="true" @change="handleFilesChange" />
          <div v-if="filePreviews.length">
            <div v-for="(preview, index) in filePreviews" :key="index" class="mt-2">
              <img :src="preview" class="img-thumbnail" style="max-width: 100px; max-height: 100px;">
            </div>
          </div>
        </b-form-group>

        <b-button type="submit" variant="primary">
          Submit Report
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormFile, BFormTextarea, BFormRadioGroup, BFormDatepicker, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BFormRadioGroup,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  data() {
    return {
      reportData: {
        report_type: '',
        time_zone: '',
        occurence_type: null,
        clientname: null,
        client_display_name: '',
        sitename: null,
        site_address: '',
        police_division: '',
        occurrence_place: '',
        occurrence_date: '',
        // Additional fields initialized here
        details: '',
        is_open: '',
      },
      occurrenceTypes: [], // Populate with your data
      clients: [], // Populate with your data
      sites: [], // Populate with your data
      statusOptions: [
        { text: 'Open', value: 'open' },
        { text: 'Closed', value: 'closed' },
      ],
      files: [],
      filePreviews: [],
    }
  },
  methods: {
    submitForm() {
      // Handle form submission, including file uploads
      console.log('Form data:', this.reportData)
      // You might need to handle file uploads separately depending on your backend
    },
    handleFilesChange() {
      this.filePreviews = Array.from(this.files).map(file => URL.createObjectURL(file))
    },
  },
}
</script>

<style>
/* Add any additional styling here */
</style>
